<template>
  <div v-if="loaded">
    <div class="row">
      <div class="col-12">
        <h1>
          <span class="schoolName">{{ school_information.name }} </span> Program
          Summary
        </h1>
      </div>
    </div>
    <!-- Student Athlete Payment totals -->
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                <h5 class="card-category">
                  {{
                    transactionTotalsCategories[
                      saTransactionsLineGraph.activeIndex
                    ]
                  }}
                </h5>
                <h2 class="card-title">
                  Student Athletes -
                  {{
                    transactionTotalsCategories[
                      saTransactionsLineGraph.activeIndex
                    ]
                  }}
                </h2>
              </div>
              <div class="col-sm-6">
                <div
                  class="btn-group btn-group-toggle"
                  :class="isRTL ? 'float-left' : 'float-right'"
                  data-toggle="buttons"
                >
                  <label
                    v-for="(option, index) in transactionTotalsCategories"
                    :key="option"
                    class="btn btn-sm btn-primary btn-simple"
                    :class="{
                      active: saTransactionsLineGraph.activeIndex === index,
                    }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initTotalTransactionsBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="saTransactionsLineGraph.activeIndex === index"
                    />
                    {{ option }}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              ref="bigChart"
              chart-id="big-line-chart"
              :chart-data="saTransactionsLineGraph.chartData"
              :gradient-colors="saTransactionsLineGraph.gradientColors"
              :gradient-stops="saTransactionsLineGraph.gradientStops"
              :extra-options="saTransactionsLineGraph.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>
    </div>
    <!-- Start of Sport Summary -->
    <div class="row">
      <div class="col-2">
        <h3
          :style="{ 'font-size': '1rem', cursor: 'pointer' }"
          @click="handleSportReportChange"
        >
          <span :style="{ 'border-bottom': '1px solid white' }">
            <i class="tim-icons icon-chart-bar-32"></i> Sport Summary
            <!-- <span v-if="loadSportSummaryTable"> Graphs </span>
            <span v-if="!loadSportSummaryTable"> Tables </span> -->
            <span :style="{ 'font-style': 'italic' }">
              {{ currentYear }}
            </span>
          </span>
        </h3>
      </div>
    </div>
    <div v-if="loadSportSummary">
      <div class="row">
        <div class="col-6">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">
                <i class="tim-icons icon-credit-card text-info"></i> Commercial
                & Membership
              </h5>
              <h3 class="card-title">
                Remaining Balance:
                <span :class="balanceClassGeneral"
                  >${{
                    Math.round(Number(totalGeneralFunding)) | currency
                  }}</span
                >
              </h3>
            </template>
            <div class="chart-area">
              <bar-chart
                style="height: 100%"
                chart-id="blue-bar-chart"
                :chart-data="commercialBarChart.chartData"
                :gradient-stops="commercialBarChart.gradientStops"
                :extra-options="commercialBarChart.extraOptions"
              >
              </bar-chart>
            </div>
          </card>
        </div>
        <!-- Donation Funding -->
        <div class="col-6">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">
                <i class="tim-icons icon-gift-2 text-info"></i> 501c(3)
                Donations
              </h5>
              <h3 class="card-title">
                Remaining Balance:
                <span :class="balanceClassDonation"
                  >${{
                    Math.round(Number(totalDonationFunding)) | currency
                  }}</span
                >
              </h3>
            </template>
            <div class="chart-area">
              <bar-chart
                style="height: 100%"
                chart-id="blue-bar-chart"
                :chart-data="donationBarChart.chartData"
                :gradient-stops="donationBarChart.gradientStops"
                :extra-options="donationBarChart.extraOptions"
                v-if="loadSportSummary"
              >
              </bar-chart>
            </div>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">
                <i class="tim-icons icon-money-coins text-info"></i> Total
                Orginization Funding
              </h5>
              <h3 class="card-title">
                Remaining Balance:
                <span :class="balanceClassTotal"
                  >${{
                    Math.round(Number(totalOrginizationFunding)) | currency
                  }}</span
                >
              </h3>
            </template>
            <div class="chart-area">
              <bar-chart
                style="height: 100%"
                chart-id="blue-bar-chart"
                :chart-data="totalBarChart.chartData"
                :gradient-stops="totalBarChart.gradientStops"
                :extra-options="totalBarChart.extraOptions"
                v-if="loadSportSummary"
              >
              </bar-chart>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div v-if="loadSportSummaryTable">
      <SportSummaryTable
        :generalFundingData="this.generalFundingData"
        :donationFundingData="this.donationFundingData"
        :totalFundingData="this.totalFundingData"
      />
    </div>
    <!-- End of Sport Summary -->
    <!-- Start of toal Summary -->
    <div class="row">
      <div class="col-2">
        <h3
          :style="{ 'font-size': '1rem', cursor: 'pointer' }"
          @click="handleTotalReportChange"
        >
          <span :style="{ 'border-bottom': '1px solid white' }">
            <i class="tim-icons icon-chart-bar-32"></i> Funding Summary
            <span :style="{ 'font-style': 'italic' }">
              {{ currentYear }}
            </span>
          </span>
        </h3>
      </div>
    </div>
    <div>
      <div v-if="loadTotalSummary">
        <div class="row">
          <div class="col-6">
            <card type="chart">
              <template slot="header">
                <h5 class="card-category">
                  <i class="tim-icons icon-credit-card text-info"></i>
                  Commercial & Membership
                </h5>
                <h3 class="card-title">
                  Total (NET):
                  <span :class="balanceClassFundingCommerical"
                    >${{
                      Math.round(Number(totalSummaryFundingCommercial))
                        | currency
                    }}</span
                  >
                </h3>
              </template>
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  chart-id="blue-bar-chart"
                  :chart-data="commercialSummaryBarChart.chartData"
                  :gradient-stops="commercialSummaryBarChart.gradientStops"
                  :extra-options="commercialSummaryBarChart.extraOptions"
                >
                </bar-chart>
              </div>
            </card>
          </div>
          <div class="col-6">
            <card type="chart">
              <template slot="header">
                <h5 class="card-category">
                  <i class="tim-icons icon-gift-2 text-info"></i> 501c(3)
                  Donations
                </h5>
                <h3 class="card-title">
                  Total (NET):
                  <span :class="balanceClassFundingDonation"
                    >${{
                      Math.round(Number(totalSummaryFundingDonation)) | currency
                    }}</span
                  >
                </h3>
              </template>
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  chart-id="blue-bar-chart"
                  :chart-data="donationSummaryBarChart.chartData"
                  :gradient-stops="donationSummaryBarChart.gradientStops"
                  :extra-options="donationSummaryBarChart.extraOptions"
                >
                </bar-chart>
              </div>
            </card>
          </div>
        </div>
      </div>
      <div v-if="loadTotalSummaryTable">
        <TotalSummaryTable :funding="totalMonthlyFunding" />
      </div>
    </div>
    <!-- End of Total Summary -->
  </div>
</template>
<script>
// Charts
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
// Config variables
import config from "@/config";
import * as chartConfigs from "@/components/Charts/config";
import SportSummaryTable from "../Dashboard/SportSummaryTable.vue";
import TotalSummaryTable from "../Dashboard/TotalSummaryTable.vue";

export default {
  components: {
    LineChart,
    BarChart,
    SportSummaryTable,
    TotalSummaryTable,
  },
  data() {
    return {
      loaded: false,
      loadBigChart: false,
      loadTotalSummary: false,
      loadTotalSummaryTable: false,
      loadSportSummaryTable: false,
      loadSportSummary: false,
      isBalanced: true,
      school_information: {},
      monthlyStudentAthleteTransactions: [],
      countedData: [],
      generalFundingData: [],
      commercialFundingAthletePayments: [],
      donationFundingData: [],
      donationFundingAthletePayments: [],
      totalFundingData: [],
      totalFundingAthletePayments: [],
      totalMonthlyFunding: [],
      totalGeneralFunding: 0,
      totalDonationFunding: 0,
      totalOrginizationFunding: 0,
      totalSummaryFundingCommercial: 0,
      totalSummaryFundingDonation: 0,
      totalSummaryFunding: 0,
      saTransactionsLineGraph: {
        allData: [
          [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
          [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
          [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [{}],
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
        },

        extraOptions: chartConfigs.purpleChartOptionsNotMoney,

        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: [],
      },
      donationBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ["USA", "GER", "AUS"],
          datasets: [
            {
              label: "Funding",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 1,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45],
            },
            {
              label: "Student Athlete Payments",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 1,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
      donationSummaryBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ["USA", "GER", "AUS"],
          datasets: [
            {
              label: "Funding",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 1,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
      commercialBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ["USA", "GER", "AUS"],
          datasets: [
            {
              label: "Funding",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 1,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45],
            },
            {
              label: "Student Athlete Payments",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 1,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
      commercialSummaryBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ["USA", "GER", "AUS"],
          datasets: [
            {
              label: "Funding",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 1,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
      totalBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ["USA", "GER", "AUS"],
          datasets: [
            {
              label: "Funding",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 1,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45],
            },
            {
              label: "Student Athlete Payments",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 1,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
    };
  },
  async mounted() {
    await this.initForReload();
  },
  watch: {
    "$route.params.schoolName": {
      handler: async function () {
        await this.initForReload();
      },
      immediate: true,
    },
  },
  methods: {
    async initForReload() {
      this.loaded = false;
      await this.initPage();
      await this.initDashboardData();

      this.loaded = true;
    },

    async initPage() {
      const id = this.$route.params.schoolName;
      await this.$http
        .get(`${process.env.VUE_APP_BASE_URL}/api/schools/${id}`)
        .then((res) => {
          this.school_information = res.data.school;
        })
        .catch((err) => console.log(err));
    },

    async initDashboardData() {
      const id = this.$route.params.schoolName;
      await this.$http
        .get(`${process.env.VUE_APP_BASE_URL}/api/dashboard/${id}`)
        .then((res) => {
          const totalMonthlyTransactions = res.data.transactions;
          this.monthlyStudentAthleteTransactions = totalMonthlyTransactions;
          this.generalFundingData = res.data.general_funding;
          this.donationFundingData = res.data.donation_funding;
          this.totalFundingData = res.data.total_funding;
          this.totalMonthlyFunding = res.data.monthly_funding;

          this.initTotalTransactionsBigChart(0);
          this.initGeneralFundingBarGraph();
          this.initDonationFundingBarGraph();
          this.initTotalFundingBarGraph();
          this.initTotalFundingSummaryGraphs();
          this.initTotalDonationFundingSummaryGraphs();

          this.loadSportSummary = true;
          this.loadTotalSummary = true;
        })
        .catch((err) => console.log(err));
    },
    initTotalTransactionsBigChart(index) {
      // reduce the data from monthlyStudentAthleteTransactions

      const totalMonthlyAmounts = this.monthlyStudentAthleteTransactions.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.total_amount);
          return accumulator;
        },
        []
      );

      if (totalMonthlyAmounts > 0) {
        this.isBalanced = true;
      } else {
        this.isBalanced = false;
      }

      //   console.log(this.monthlyStudentAthleteTransactions);
      const totalMonthlyTransactions =
        this.monthlyStudentAthleteTransactions.reduce(
          (accumulator, currentValue) => {
            accumulator.push(currentValue.total_transactions);
            return accumulator;
          },
          []
        );

      this.countedData.push(totalMonthlyTransactions);
      this.countedData.push(totalMonthlyAmounts);

      let chartData = {
        datasets: [
          {
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // Data is changed here
            data: this.countedData[index],
          },
        ],
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
      };

      this.$refs.bigChart.updateGradients(chartData);
      this.saTransactionsLineGraph.chartData = chartData;
      this.saTransactionsLineGraph.activeIndex = index;

      this.saTransactionsLineGraph.extraOptions =
        chartConfigs.purpleChartOptionsNotMoney;
    },
    initGeneralFundingBarGraph() {
      this.totalGeneralFunding =
        this.generalFundingData.length > 0
          ? this.generalFundingData.reduce((sum, item) => {
              return (
                sum +
                parseFloat(item.total_general_funding) -
                parseFloat(item.total_student_athlete_transactions)
              );
            }, 0)
          : 0;

      const fundingData = this.generalFundingData.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.total_general_funding);
          return accumulator;
        },
        []
      );

      const studentAthleteTransactions = this.generalFundingData.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.total_student_athlete_transactions);
          return accumulator;
        },
        []
      );

      // console.log(studentAthleteTransactions);

      let chartData = {
        datasets: [
          {
            barPercentage: 0.5,
            categoryPercentage: 0.4,
            label: "Data1",
            fill: true,
            borderColor: "blue",
            backgroundColor: config.colors.info,
            borderWidth: 1,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // Data is changed here
            data:
              this.generalFundingData.length > 0 ? fundingData : [0, 0, 0, 0],
          },
          {
            barPercentage: 0.5,
            categoryPercentage: 0.4,
            label: "Data2",
            fill: true,
            borderColor: "blue",
            backgroundColor: "rgba(220, 64, 106, 0.58)",
            borderWidth: 0.1,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // Data is changed here
            // data: this.generalFundingData.length > 0 ? fundingData : [0, 0, 0, 0],
            data: studentAthleteTransactions,
          },
        ],
        labels:
          this.generalFundingData.length > 0
            ? this.generalFundingData.reduce((accumulator, currentValue) => {
                accumulator.push(currentValue.sport);
                return accumulator;
              }, [])
            : ["Football", "Baseball", "Mens Basketball"],
      };

      this.commercialBarChart.chartData = chartData;
    },
    initDonationFundingBarGraph() {
      const totalDonationFunding =
        this.donationFundingData.length > 0
          ? this.donationFundingData.reduce((sum, item) => {
              return (
                sum +
                parseFloat(item.total_donation_funding) -
                parseFloat(item.total_student_athlete_transactions)
              );
            }, 0)
          : 0;
      this.totalDonationFunding = totalDonationFunding;

      const fundingData = this.donationFundingData.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.total_donation_funding);
          return accumulator;
        },
        []
      );

      const studentAthleteTransactions = this.donationFundingData.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.total_student_athlete_transactions);
          return accumulator;
        },
        []
      );

      let chartData = {
        datasets: [
          {
            barPercentage: 0.5,
            categoryPercentage: 0.4,
            label: "Data1",
            fill: true,
            borderColor: "blue",
            backgroundColor: config.colors.info,
            borderWidth: 1,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // Data is changed here
            data:
              this.donationFundingData.length > 0 ? fundingData : [0, 0, 0, 0],
          },
          {
            barPercentage: 0.5,
            categoryPercentage: 0.4,
            label: "Data2",
            fill: true,
            borderColor: "blue",
            backgroundColor: "rgba(220, 64, 106, 0.58)",
            borderWidth: 0.1,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // Data is changed here
            // data: this.donationFundingData.length > 0 ? fundingData : [0, 0, 0, 0],
            data: studentAthleteTransactions,
          },
        ],
        labels:
          this.donationFundingData.length > 0
            ? this.donationFundingData.reduce((accumulator, currentValue) => {
                accumulator.push(currentValue.sport);
                return accumulator;
              }, [])
            : ["Football", "Baseball", "Mens Basketball"],
      };

      this.donationBarChart.chartData = chartData;
    },
    initTotalFundingBarGraph() {
      const totalOrginizationFunding =
        this.totalFundingData.length > 0
          ? this.totalFundingData.reduce((sum, item) => {
              return (
                sum +
                parseFloat(item.total_funding) -
                parseFloat(item.total_student_athlete_transactions)
              );
            }, 0)
          : 0;
      this.totalOrginizationFunding = totalOrginizationFunding;

      const fundingData = this.totalFundingData.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.total_funding);
          return accumulator;
        },
        []
      );

      const studentAthleteTransactions = this.totalFundingData.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.total_student_athlete_transactions);
          return accumulator;
        },
        []
      );

      let chartData = {
        datasets: [
          {
            barPercentage: 0.5,
            categoryPercentage: 0.4,
            label: "Data1",
            fill: true,
            borderColor: "blue",
            backgroundColor: config.colors.info,
            borderWidth: 1,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // Data is changed here
            data: this.totalFundingData.length > 0 ? fundingData : [0, 0, 0, 0],
          },
          {
            barPercentage: 0.5,
            categoryPercentage: 0.4,
            label: "Data2",
            fill: true,
            borderColor: "blue",
            backgroundColor: "rgba(220, 64, 106, 0.58)",
            borderWidth: 0.1,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // Data is changed here
            // data: this.totalFundingData.length > 0 ? fundingData : [0, 0, 0, 0],
            data: studentAthleteTransactions,
          },
        ],
        labels:
          this.totalFundingData.length > 0
            ? this.totalFundingData.reduce((accumulator, currentValue) => {
                accumulator.push(currentValue.sport);
                return accumulator;
              }, [])
            : ["Football", "Baseball", "Mens Basketball"],
      };

      this.totalBarChart.chartData = chartData;
    },
    initTotalFundingSummaryGraphs() {
      const totalSummaryFundingCommercial =
        this.totalMonthlyFunding.length > 0
          ? this.totalMonthlyFunding.reduce((sum, item) => {
              return sum + parseFloat(item.total_general_funding);
            }, 0)
          : 0;
      this.totalSummaryFundingCommercial = totalSummaryFundingCommercial;

      const fundingData = this.totalMonthlyFunding.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.total_general_funding);
          return accumulator;
        },
        []
      );

      let chartData = {
        datasets: [
          {
            barPercentage: 0.5,
            categoryPercentage: 0.4,
            label: "Month",
            fill: true,
            borderColor: "blue",
            backgroundColor: "green",
            borderWidth: 1,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "green",
            pointBorderColor: "green",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // Data is changed here
            data:
              this.totalMonthlyFunding.length > 0 ? fundingData : [0, 0, 0, 0],
          },
        ],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      };

      this.commercialSummaryBarChart.chartData = chartData;
    },
    initTotalDonationFundingSummaryGraphs() {
      const totalSummaryFundingDonation =
        this.totalMonthlyFunding.length > 0
          ? this.totalMonthlyFunding.reduce((sum, item) => {
              return sum + parseFloat(item.total_donation_funding);
            }, 0)
          : 0;
      this.totalSummaryFundingDonation = totalSummaryFundingDonation;

      const fundingData = this.totalMonthlyFunding.reduce(
        (accumulator, currentValue) => {
          accumulator.push(currentValue.total_donation_funding);
          return accumulator;
        },
        []
      );

      let chartData = {
        datasets: [
          {
            barPercentage: 0.5,
            categoryPercentage: 0.4,
            label: "Month",
            fill: true,
            borderColor: "blue",
            backgroundColor: "green",
            borderWidth: 1,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "green",
            pointBorderColor: "green",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            // Data is changed here
            data:
              this.totalMonthlyFunding.length > 0 ? fundingData : [0, 0, 0, 0],
          },
        ],
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      };

      this.donationSummaryBarChart.chartData = chartData;
    },
    handleSportReportChange() {
      this.loadSportSummary = !this.loadSportSummary;
      this.loadSportSummaryTable = !this.loadSportSummaryTable;
    },
    handleTotalReportChange() {
      this.loadTotalSummary = !this.loadTotalSummary;
      this.loadTotalSummaryTable = !this.loadTotalSummaryTable;
    },
  },
  computed: {
    transactionTotalsCategories() {
      return ["Total Transactions", "Payment Totals"];
    },
    loadData() {
      return false;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    balanceClassGeneral() {
      return this.totalGeneralFunding > 0 ? "text-success" : "text-danger";
    },
    balanceClassDonation() {
      return this.totalDonationFunding > 0 ? "text-success" : "text-danger";
    },
    balanceClassTotal() {
      return this.totalOrginizationFunding > 0 ? "text-success" : "text-danger";
    },
    balanceClassFundingCommerical() {
      return this.totalSummaryFundingCommercial > 0
        ? "text-success"
        : "text-danger";
    },
    balanceClassFundingDonation() {
      return this.totalSummaryFundingDonation > 0
        ? "text-success"
        : "text-danger";
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style>
.schoolName {
  font-weight: bold;
}
.el-table th .cell {
  font-weight: bold;
  font-size: 0.57rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-table th .cell:hover {
  overflow: visible;
  white-space: pre;
}
</style>
